import axios from 'axios'
import store from '@/store'
import storage from 'store'
// import notification from "ant-design-vue/es/notification";
import { VueAxios } from './axios'
import router from '@/router'
import { message, Modal, notification } from 'ant-design-vue'

const ACCESS_TOKEN = 'token'
const USER_INFO = 'userInfo'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  // baseURL: "https://api.deer.funengyun.cn/api",
  // baseURL: 'https://api.luyopay.com/api',
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 100000, // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    const token = storage.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message,
      })
    }
    if (
      error.response.status === 401 &&
      !(data.result && data.result.isLogin)
    ) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed',
      })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
    if (error.response.status === 500) {
      Modal.destroyAll()
      Modal.error({
        title: '提示',
        content: '发生了一个意外错误，请稍后再试，如果仍然不行，请联系客服人员',
        cancelText: '取消',
        okText: '确定',
        onOk: () => {
          /* storage.clearAll();
          router.push("/user/user"); */
        },
      })
    }
    store.dispatch('loading/endContentLoading')
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use((config) => {
  const token = storage.get(ACCESS_TOKEN)
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers[ACCESS_TOKEN] = token
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  if (response.data.code === -9999) {
    Modal.destroyAll()
    message.destroy()

    let secondsToGo = 5
    const modal = Modal.error({
      title: '提示',
      content:
        response.data.msg ||
        response.data.message ||
        '发生了一个意外错误，请稍后再试，如果仍然不行，请联系客服人员',
      okText: '确定',
      onOk: () => {
        storage.clearAll()
        router.push('/official/officialLogin')
      },
    })
    const interval = setInterval(() => {
      secondsToGo -= 1
      modal.update({
        okText: `确定 ${secondsToGo}`,
      })
    }, 1000)
    setTimeout(() => {
      clearInterval(interval)
      modal.destroy()
      router.push('/official/officialLogin')
    }, secondsToGo * 1000)
  } else if (response.data.code !== 0) {
    message.destroy()
    message.error(
      response.data.msg ||
        response.data.message ||
        '发生了一个意外错误，请稍后再试，如果仍然不行，请联系客服人员'
    )
  }
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  },
}

export default request

export { installer as VueAxios, request as axios, ACCESS_TOKEN, USER_INFO }
