/*
 * 登录接口
 * */
import request from '@/utils/request'

/*
 * 获取登录地址
 * */
export function getLoginUrl() {
  return request({
    url: '/work/get_login_url',
    method: 'get',
  })
}

/*
 * 企业微信获取授权地址
 * */
export function getAuthUrl(parameter) {
  return request({
    url: '/work/get_auth_url',
    method: 'get',
    params: parameter,
  })
}

/*
 * 通过authCode登录
 * */
export function authLogin(parameter) {
  return request({
    url: '/work/login',
    method: 'get',
    params: parameter,
  })
}

/*
 * 退出登录
 * */
export function logout() {
  return request({
    url: '/work/logout',
    method: 'get',
  })
}

/*
 * 获取信息
 * */
export function getUserInfo() {
  return request({
    url: '/work/login_info',
    method: 'get',
  })
}
